import '@rei/cedar/dist/reset.css';
import '@rei/cedar/dist/cdr-fonts.css';
import '@rei/cedar/dist/style/cdr-img.css';
import '@rei/cedar/dist/style/cdr-text.css';
import '@rei/cedar/dist/style/cdr-button.css';
import '@rei/cedar/dist/style/cdr-card.css';
import '@rei/cedar/dist/style/cdr-container.css';
import '@rei/cedar/dist/style/cdr-grid.css';
import '@rei/cedar/dist/style/cdr-link.css';
import '@rei/cedar/dist/style/cdr-modal.css';
import '@rei/cedar/dist/style/cdr-accordion.css';
import '@rei/cedar/dist/style/cdr-icon.css';
import '@rei/cedar/dist/style/cdr-popover.css';
import '@rei/cedar/dist/style/cdr-input.css';
import '@rei/cedar/dist/style/cdr-select.css';
import '@rei/autocomplete/dist/style.css';
import '@rei/geolocate-button/dist/style.css';
import createApp from './main';
import { getClientPageData } from '../../common/dom';

const { pageData: props } = getClientPageData();

const { app } = createApp(props);
app.mount('#app');
